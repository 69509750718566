import { MouseEvent, useEffect, useRef, useState } from 'react'
import DateSubField from './MobileDateSubField'
import { DatePickerProps, SUBFIELD, Value } from './type'

const DateField = (props: DatePickerProps) => {
  const { day, month, year } = props.value || {}
  const [hasError, setHasError] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const [activeSubField, setActiveSubField] = useState<SUBFIELD | null>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setIsEmpty(!day && !month && !year)
  }, [day, month, year])

  useEffect(() => {
    props.handleFieldError(hasError)
  }, [hasError])

  useEffect(() => {
    activeSubField !== null ? inputRef.current?.focus() : inputRef.current?.blur()
  }, [activeSubField])

  useEffect(() => {
    const error =
      !day ||
      !month ||
      !year ||
      isNaN(Number(day)) ||
      isNaN(Number(month)) ||
      isNaN(Number(year)) ||
      +year < props.minYear ||
      +year > props.maxYear
    setHasError(error)
  }, [day, month, props.maxYear, props.minYear, year])

  const handleSubFieldClick = (e: MouseEvent<HTMLSpanElement>, field: SUBFIELD) => {
    e.preventDefault()
    e.stopPropagation()
    inputRef.current?.focus()
    setActiveSubField(field)
  }

  const handleChange = (key: keyof Value, value: string) => {
    props.onChange(key, value)
  }

  const highlightNext = () => {
    if (activeSubField === null) return
    const next = activeSubField < SUBFIELD.YEAR ? activeSubField + 1 : activeSubField
    setActiveSubField(next)
  }
  const highlightPrev = () => {
    if (activeSubField === null) return
    const prev = activeSubField > SUBFIELD.DAY ? activeSubField - 1 : activeSubField
    setActiveSubField(prev)
  }

  const focusState = activeSubField !== null
  const defaultState = isEmpty && props.firstEvents.day && props.firstEvents.month && props.firstEvents.year
  const className = focusState
    ? 'custom-datefield-focused'
    : defaultState
      ? 'bg-gray-50 dark:bg-gray-700 text-gray-500/25 dark:text-gray-500/75 border-gray-300 dark:border-gray-600 dark:bg-gray-700 '
      : hasError
        ? 'error bg-red-50 dark:bg-gray-700 text-red-500 border-red-500'
        : 'success bg-green-50 text-green-700 dark:bg-gray-700 dark:text-green-500 border-green-500'

  return (
    <div
      className={`icon-input flex ${className} relative mt-2 w-full rounded-lg border`}
      onClick={() => {
        if (activeSubField === null) {
          setActiveSubField(SUBFIELD.DAY)
        }
      }}
    >
      <div className={`inline-flex h-full w-fit p-4 text-lg`}>
        <DateSubField
          highlightNext={highlightNext}
          highlightPrev={highlightPrev}
          handleClick={handleSubFieldClick}
          placeholder={'DD'}
          field={SUBFIELD.DAY}
          value={day}
          onChange={val => handleChange('day', val)}
          minYear={props.minYear}
          maxYear={props.maxYear}
          readOnly={props.readOnly}
          {...{ setActiveSubField, activeSubField }}
        />
        /
        <DateSubField
          highlightNext={highlightNext}
          highlightPrev={highlightPrev}
          handleClick={handleSubFieldClick}
          placeholder={'MM'}
          field={SUBFIELD.MONTH}
          value={month}
          onChange={val => handleChange('month', val)}
          minYear={props.minYear}
          maxYear={props.maxYear}
          readOnly={props.readOnly}
          {...{ setActiveSubField, activeSubField }}
        />
        /
        <DateSubField
          highlightNext={highlightNext}
          highlightPrev={highlightPrev}
          handleClick={handleSubFieldClick}
          placeholder={'YYYY'}
          field={SUBFIELD.YEAR}
          value={year}
          onChange={val => handleChange('year', val)}
          minYear={props.minYear}
          maxYear={props.maxYear}
          readOnly={props.readOnly}
          {...{ setActiveSubField, activeSubField }}
        />
        <input
          type="tel"
          className="absolute left-0 top-0 -z-[999] h-full w-full caret-transparent"
          ref={inputRef}
          value=""
          onBlur={() => {
            setActiveSubField(null)
          }}
          onFocus={() => {
            if (activeSubField === null) {
              setActiveSubField(SUBFIELD.DAY)
            }
          }}
        />
      </div>
      {<span className="form-icon"></span>}
    </div>
  )
}

export default DateField
