export type DatePickerProps = {
  /**
   *
   * @param name - name by which component's state will be updated for two-way binding
   * @param value - value component is binded to
   * @returns void
   * Function called to update state value binded to component
   */
  onChange: (name: 'day' | 'month' | 'year', value: string) => void
  /**
   * Minimum allowed year
   */
  minYear: number
  /**
   * Maximum allowed year
   */
  maxYear: number

  /**
   * Value binded to component input
   */
  value: Record<'day' | 'month' | 'year', string>

  /**
   * object indicating whether fields have been interacted with or not
   */
  firstEvents: Record<'day' | 'month' | 'year', boolean>
  /**
   *
   * @param error - a boolean indicating whether the component has error or not
   * @returns - void
   * Function called to handle errors in dob fields
   */
  handleFieldError: (error: boolean) => void

  /**
   * @ignore
   */
  readOnly?: boolean
}

export enum SUBFIELD {
  DAY,
  MONTH,
  YEAR,
}

export type Value = { day: string; month: string; year: string }
