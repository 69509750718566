import { useRef, useEffect, MouseEvent, useState, SetStateAction, Dispatch } from 'react'
import { SUBFIELD } from './type'
import { padValue, isWithinRange } from '@qtc-repo/common/utils'

type Props = {
  handleClick: (e: MouseEvent<HTMLSpanElement>, section: SUBFIELD) => void
  placeholder: string
  field: SUBFIELD
  highlightNext: VoidFunction
  highlightPrev: VoidFunction
  onChange: (val: string) => void
  minYear: number
  maxYear: number
  readOnly?: boolean
  value?: string
  activeSubField: SUBFIELD | null
  setActiveSubField: Dispatch<SetStateAction<SUBFIELD | null>>
}

const DateSubField = (props: Props) => {
  const active = props.activeSubField === props.field
  const [currValue, setCurrValue] = useState('')
  const ref = useRef<HTMLSpanElement | null>(null)
  useEffect(() => {
    setCurrValue(props.value || '')
  }, [props.value])

  useEffect(() => {
    if (props.readOnly) return
    active
      ? window.addEventListener('keydown', keydownListener)
      : window.removeEventListener('keydown', keydownListener)
    return () => window.removeEventListener('keydown', keydownListener)
  }, [active, props.readOnly])

  useEffect(() => {
    // THIS IS ADDED TO DISABLE STORYBOOK'S DEFAULT KEYBOARD BEHAVIORS
    window.onkeyup = null
    window.onkeydown = null
  }, [])
  const validateInput = (value: string, key: string, min: number, max: number, allowLeadingZeroes = true) => {
    const filledRegex = new RegExp(`\\d{${props.placeholder.length}}`)
    if (value === props.placeholder) {
      const projectedValue = padValue(+key, max, 'end')
      if (+projectedValue > max) return
      if (!allowLeadingZeroes && key === '0') return
      value = padValue(+key, max, 'end', props.placeholder[0])
    } else if (Number(value.replace(/\D+/gi, '')) === 0) {
      value = key === '0' ? value : padValue(+key, max, 'start')
    } else {
      if (filledRegex.exec(value)) {
        value = props.placeholder
      }
      value = value.replace(/\D+/gi, '')
      // we determine the highest and lowest possible whole values user could enter and see if either is within range
      const minProjectedValue = +padValue(+(+value + key), max, 'end')
      const maxProjectedValue = +padValue(+(+value + key) + 1, max, 'end') - 1
      const minValWithinRange = isWithinRange(allowLeadingZeroes ? +(+value + key) : minProjectedValue, +min, max)
      const maxValWithinRange = isWithinRange(maxProjectedValue, +min, max)
      if (minValWithinRange || maxValWithinRange) {
        value = padValue(+(+value + key), max, 'end', props.placeholder[0])
      } else return
    }
    // automatically jump to next field once a valid value is received for a field
    if (!isNaN(+value) && filledRegex.exec(value)) {
      props.highlightNext()
    }
    return value
  }

  const specialKeys = ['Enter', 'Tab']
  function keydownListener(event: WindowEventMap['keydown']) {
    let key = event.key
    if (!specialKeys.includes(key)) event.preventDefault()
    const valueRange = {
      [SUBFIELD.DAY]: { min: 1, max: 31 },
      [SUBFIELD.MONTH]: { min: 1, max: 12 },
      [SUBFIELD.YEAR]: { min: props.minYear, max: props.maxYear },
    }
    let value = ref.current!.innerText
    const max = valueRange[props.field].max
    const min = valueRange[props.field].min
    if (key === 'ArrowLeft') {
      props.highlightPrev()
    } else if (key === 'ArrowRight') {
      props.highlightNext()
    } else if (key === 'ArrowUp') {
      const nextValue = +value + 1 <= max ? +value + 1 : min
      value = padValue(nextValue, max, 'start')
    } else if (key === 'ArrowDown') {
      const nextValue = +value - 1 >= min ? +value - 1 : max
      value = padValue(nextValue, max, 'start')
    } else if (key === 'Backspace') {
      if (value === props.placeholder) {
        props.highlightPrev()
      }
      value = value.replace(/\D+/g, '').slice(0, -1).padEnd(value.length, props.placeholder[0])
    } else if (isNaN(Number(key))) {
      return
    } else {
      value = validateInput(value, key, min, max, props.field !== SUBFIELD.YEAR) || value
    }
    setCurrValue(value)
    props.onChange(value === props.placeholder ? '' : value)
  }

  return (
    <div className="relative">
      <span
        ref={ref}
        className={`${active ? 'bg-blue-300 text-gray-700' : ''}`}
        style={{ WebkitTextFillColor: active ? '#374151' : 'inherit' }}
        //using onMouseDown cos onClick breaks the focus/blur binding to the ghost input in parent component
        onMouseDown={e => {
          props.handleClick(e, props.field)
        }}
      >
        {currValue ? currValue : props.placeholder}
      </span>
    </div>
  )
}

export default DateSubField
